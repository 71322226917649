<script>
import StudentsService from '@/service/students.service';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'modal-pre-register-student',

  components: {
    ModalRegisterStudent: () => import('./ModalRegisterStudent.vue'),
  },

  data() {
    return {
      dialog: false,
      loading: false,
      loadingSave: false,
      form: { address: {} },
      studentClasses: [],
      masked: {
        cpf: '',
        phone: '',
        address: {
          cep: '',
        },
      },
    };
  },

  created() {
    this.$on('create', this.create);
  },

  methods: {
    create() {
      this.form = {};
      this.dialog = true;
    },

    onMaska(val, field) {
      set(this.form, field, val);
    },

    async saveAndClose() {
      this.loadingSave = true;
      try {
        await this.save();
        await this.$emit('successAndClose');
      } catch (e) {
        console.error(e);
      }
      this.loadingSave = false;
    },

    async saveAndContinueEditing() {
      this.loadingSave = true;
      try {
        const student = await this.save();
        await this.$emit('successAndContinueEditing', student);
      } catch (e) {
        console.error(e);
      }
      this.loadingSave = false;
    },

    async save() {
      const form = cloneDeep(this.form);
      const student = await StudentsService.preCreate(form);

      this.dialog = false;
      this.$swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Salvo com sucesso!',
        timer: 2000,
        showConfirmButton: false,
      });
      return student;
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5">Novo aluno</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col sm="12" lg="3">
              <v-text-field
                v-model="form.cpf"
                v-mask="'###.###.###-##'"
                :value-raw="masked.cpf"
                :value="masked.cpf"
                label="CPF"
                color="white"
                hide-details="auto"
                outlined
                persistent-placeholder
                dense
                @rawValue="onMaska($event, 'cpf')"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.name"
                label="Nome completo"
                color="white"
                hide-details="auto"
                outlined
                persistent-placeholder
                dense
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="form.birthDate"
                label="Data de Nascimento"
                color="white"
                hide-details="auto"
                type="date"
                outlined
                persistent-placeholder
                dense
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> Cancelar </v-btn>
        <v-btn color="primary" :loading="loadingSave" @click="saveAndClose"> Salvar e fechar </v-btn>
        <v-btn color="success" :loading="loadingSave" @click="saveAndContinueEditing"> Salvar e continuar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
